@import '~antd/dist/antd.css';

.react-flow {
  background: #f2f2f2
}

.custom-node {
  border: 1px solid white;
  padding: 0px 0px 5px 0px;
  border-radius: 5px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  box-shadow: 0px 0px 5px 1px #333333;
  min-width: fit-content;
}

.input h4 {
  background-color: #A5A6F6;
  border-radius: 5px 5px 0px 0px;
  color: #333333;
}

.pipe h4 {
  background-color: #6FCF97;
  border-radius: 5px 5px 0px 0px;
}

.display h4:first-of-type {
  background-color: #87C3FA;
  border-radius: 5px 5px 0px 0px;
}

.custom-node h4 {
  width: 100%;
  text-align: center;
}

.input {
  width: 50%;
  margin-left: 25%;
}

/*
Ant design css overwrites
*/

.ant-input-number {
  border-radius: 5px;
}